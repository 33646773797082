@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0D0E1A;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-container {
  @apply fixed top-0 flex justify-end pr-[122px] h-[110px] w-full bg-no-repeat;
}

.nav-item {
  @apply pl-2 pr-2 text-white font-medium text-[18px] uppercase cursor-pointer select-none transition-all hover:text-[#14C2A3];
}
.nav-item-mobile {
  @apply p-2 text-black font-medium text-[14px] uppercase cursor-pointer select-none transition-all hover:text-[#14C2A3];
}

.active-nav-item {
  @apply pl-2 pr-2 text-[#14C2A3] font-bold text-[18px] uppercase cursor-pointer select-none;
}

.active-nav-item-mobile {
  @apply p-2 text-[#14C2A3] font-bold text-[14px] uppercase cursor-pointer select-none;
}

.footer-social-icon {
  @apply pl-4 pr-4 h-[108px] cursor-pointer transition-all;
}

.banner-section {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.newsletter-bg {
  background-size: cover !important;
  animation: animatedBackground 500s linear infinite;
}

@media only screen and (min-width: 1540px) {
  .header-container {
    height: 190px !important;
  }
  .nav-container > div > span {
    font-size: 30px !important;
  }
  .hero-section {
    height: 70vh !important;
  }
  .run-down-section {
    height: 100vh !important;
  }
  .banner-section {
    background-position: top !important;
    height: 600px !important;
  }
  .banner-content > img {
    width: 860px !important;
  }
  .banner-content > span {
    width: 55% !important;
    font-size: 42px !important;
  }
  .footer-section {
    height: 804px !important;
  }
  .footer-title {
    height: 52px !important;
  }
  .footer-social-icon {
    height: 158px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .header-container {
    background-size: 100% !important;
  }
}

@media only screen and (max-width: 780px) {
  .header-container {
    background-image: url('../src/assets/components/header-mobile.svg') !important;
  }
  .nav-desktop {
    display: none !important;
  }
  .nav-mobile {
    display: flex;
  }
}

@media only screen and (min-width: 700px) {
  .banner-section {
    background-position: top !important;
  }
}

@media only screen and (max-width: 580px) {
  .newsletter-bg {
    background-size: 185% !important;
  }
  .run-down-section {
    background-image: url('../src/assets/backgrounds/run-down-section-mobile.png') !important;
  }
  .hero-container {
    background-image: url('../src/assets/backgrounds/hero-section-mobile.png') !important;
  }
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -10000px;
  }
}